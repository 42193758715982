<template>
<div class="page-home">
    <div class="overview-section display__flex align_items__center justify_content__space_between">
      <div class="left-view display__flex align_items__center">
        <div>{{$t('欢迎回来，')}}{{userInfo.userName || ''}}</div>
        <!-- <div class="left-item display__flex">
          <div>部门数 
            <span v-if="summary.deptNum!==undefined && summary.deptNum!==''" style="width:48px">{{ summary.deptNum }}</span>
            <span v-else>{{ '' | default_chars }}</span>
          </div>
          <div>员工数 
            <span v-if="summary.userNum!==undefined && summary.userNum!==''">{{ summary.userNum }}</span>
            <span v-else>{{ '' | default_chars }}</span>
          </div>
        </div> -->
      </div>
      <div class="right-view">{{dateDesc()}}</div>
    </div>

    <div class="panel-section" v-if="(pageButtonPermission('hrms/home', 'addDeptButton') || pageButtonPermission('hrms/home', 'addUserButton'))">
      <div class="panel-title section-stick">{{$t('快捷入口')}}</div>
      <div class="display__flex flex_wrap__wrap">
        <div style="cursor: pointer;" class="panel-item panel-animat display__flex flex_direction__column align_items__center justify_content__center" v-if="pageButtonPermission('hrms/home', 'addUserButton')" @click="goCreateOrg('create-user')">
          <img src="../../assets/images/create-user.png"/>
          <div>{{$t('新增员工')}}</div>
        </div>
        <!-- <div style="cursor: pointer;" class="panel-item panel-animat display__flex flex_direction__column align_items__center justify_content__center" v-if="pageButtonPermission('hrms/home', 'addDeptButton')" @click="goCreateOrg('create-dept')">
          <img src="../../assets/images/create-dept.png">
          <div>新建部门</div>
        </div> -->
      </div>
    </div>

    <div class="panel-section">
      <div class="panel-title section-stick">{{$t('已订购产品')}}</div>
      <div class="display__flex flex_wrap__wrap">
        <div class="panel-item panel-animat display__flex flex_direction__column align_items__center justify_content__center" v-for="item in purchaseList" :key="item.id">
          <img v-if="item.icon" :src="item.icon"/>
          <div>{{item.name}}</div>
        </div>
      </div>
    </div>

    <!-- <div class="panel-section" v-if="pageButtonPermission('hrms/home', 'nonuseProductButton')">
      <div class="panel-title section-stick">待启用产品 <span class="panel-subtitle">(启用方式： 请拨打010-57835586)</span></div>
      <div class="display__flex flex_wrap__wrap">
        <div class="panel-item disable display__flex flex_direction__column align_items__center justify_content__center" v-for="item in unPurchaseList" :key="item.id">
          <img v-if="item.icon" :src="item.icon">
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div> -->

    <div v-if="pageLoading" v-loading="pageLoading" class="loading-mask"></div>
  </div>
</template>

<script>
import { intl } from "@tci18n/vue2";import { mapGetters } from 'vuex';
import dateHelper from '@/libs/datetime';
import basicHelper from '@/view/common/basic';
import serviceAPI from './api';

/**
 * Home 页面
 * @module @/view/home
 */
export default {
  name: 'Home',
  data() {
    return {
      pageLoading: false,

      summary: {},
      // 已订购产品列表
      purchaseList: [],
      // 未订购产品列表
      unPurchaseList: []
    };
  },
  computed: {
    ...mapGetters([
    'userInfo',
    'pageButtonPermission']
    )
  },
  created() {
    this.groupRequestData();
  },
  methods: {
    /**
     * 获取日期文本描述
     */
    dateDesc() {
      let dateNow = new Date();
      const weeks = [intl.$t("星期日"), intl.$t("星期一"), intl.$t("星期二"), intl.$t("星期三"), intl.$t("星期四"), intl.$t("星期五"), intl.$t("星期六")];
      return dateHelper.format(dateNow, intl.$t("MM月dd日")) + ' ' + weeks[dateNow.getDay()];
    },

    /**
     * 组合请求数据
     */
    groupRequestData() {
      // const p1 = this.getSummary();
      const p2 = this.getProductList();
      this.pageLoading = true;
      // Promise.all([p1, p2]).then(res => {
      Promise.all([p2]).then((res) => {
        this.pageLoading = false;
      }).catch((error) => {
        this.pageLoading = false;
      });
    },

    /**
     * 获取统计数据
     */
    getSummary() {
      serviceAPI.getSummary().then((res) => {
        if (res.code == 200) {
          this.summary = res.data;
        }
      }).catch((exec) => {
        console.error('Get summary exception: ', exec.message);
      });
    },

    /**
     * 获取产品列表
     */
    getProductList() {
      serviceAPI.getProductList().then((res) => {
        if (res.code == 200) {
          // 从数据源中过滤“云平台”等项目
          let excludeCodes = [basicHelper.XCLOUD_PROJECT_CODE];
          this.purchaseList = res.data.purchaseList ? res.data.purchaseList.filter((item) => !excludeCodes.includes(item.projectCode)) : [];
          this.unPurchaseList = res.data.unPurchaseList ? res.data.unPurchaseList.filter((item) => !excludeCodes.includes(item.projectCode)) : [];
        }
      }).catch((exec) => {
        console.error('Get product list exception: ', exec.message);
      });
    },

    /**
     * “新增员工/新建部门”操作
     */
    goCreateOrg(action) {
      this.$router.push({ name: 'organization', params: { action } });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/mixins";

  .page-home {
    padding: 16px;
    position: relative;
  }

  .overview-section {
    padding: 14px 24px 14px 20px;
    background: url("https://file.40017.cn/zhuketong/hrms/image/home_banner.png") no-repeat;
    background-size: 100% 100%;
  }

  .left-view {
    font-size: 20px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 20px;
    font-weight: bold;
  }

  .left-item {
    font-size: 16px;
    font-weight: normal;
    color: #FFFFFF;
    margin-left: 100px;
  }

  .left-item div::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #FFFFFF;
    border-radius: 4px;
    margin-right: 6px;
  }

  .left-item div {
    width: 168px;
  }

  .left-item span {
    font-size: 20px;
    font-weight: 600;
    margin-left: 6px;
    vertical-align: top;
  }

  .right-view {
    background-color: rgba(120, 158, 248, 0.6);
    border-radius: 20px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 1;
    padding: 11px 15px;
  }


  .panel-section {
    @include section-stick($width: 3px, $height: 14px, $pl: 9px);

    background: #FFFFFF;
    border-radius: 2px;
    margin-top: 12px;
    padding: 20px 20px 0 20px;
    min-height: 174px;
  }

  .panel-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .panel-item {
    background-color: #FFFFFF;
    border: 0.69px solid #DEE3EE;
    border-radius: 2px;
    font-size: 12px;
    text-align: center;
    width: 100px;
    height: 100px;
    margin-right: 16px;
    margin-bottom: 20px;
    padding: 0 10px;
  }

  .panel-animat {
    transition: all 0.4s;
  }

  .panel-animat:hover {
    box-shadow: 0 0 6px 0 rgba(73,124,246,0.18);
    border-radius: 1.39px;
    transform:translateY(-4px);
  }

  .panel-item img {
    max-width: 34px;
    max-height: 34px;
    margin-bottom: 10px;
  }

  .panel-item.disable {
    background-color: #F4F6F9;
    border: 1px solid #DEE3EE;
    cursor: not-allowed;
  }

  .panel-subtitle {
    font-size: 14px;
    color: #AAB1BD;
    letter-spacing: 0;
    line-height: 14px;
    font-weight: normal;
    margin-left: 10px;
  }

  .loading-mask {
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    top: 16px;
    right: 16px;
    bottom: 16px;
    left: 16px;
  }
</style>
